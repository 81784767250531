@use 'assets/styles/utils/helper' as h;

.progress {
  cursor: pointer;
  position: relative;
  width: 100%;
  appearance: none;
  height: h.rem(3px);
  margin: h.rem(0 0 20px);
  border: none;
  color: var(--white);
  background-color: rgba(var(--white-rgb), .2);

  &::-webkit-progress-bar {
    background-color: rgba(var(--white-rgb), .2);
  }

  &::-webkit-progress-value {
    background-color: var(--white);
  }

  &::-moz-progress-bar {
    background-color: var(--white);
  }

  &.isLive {
    background-color: #f00;

    &::-webkit-progress-bar {
      background-color: #f00;
    }

    &::-webkit-progress-value {
      background-color: #f00;
    }

    &::-moz-progress-bar {
      background-color: #f00;
    }
  }
}

.tooltip {
  font-family: var(--founders-mono);
  font-weight: normal;
  font-size: h.rem(11px);
  line-height: h.rem(25px);
  display: block;
  color: var(--white);
  padding: 0 h.rem(10px);
  height: h.rem(25px);
  background: rgba(var(--white-rgb), .2);
  position: absolute;
  top: h.rem(-30px);
  transition: opacity .3s;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: h.rem(-8px);
    left: 0;
    border-left: 4px solid rgba(var(--white-rgb), .2);
    border-top: 4px solid rgba(var(--white-rgb), .2);
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }
}

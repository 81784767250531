@use 'assets/styles/utils/helper' as h;
/* stylelint-disable no-descending-specificity */
.videoSlate {
  --video-slate-gamma-flag-height: 70px;
  --video-slate-gamma-flag-width: 124px;
  --video-slate-flag-max-height: 30%;
  --video-slate-flag-max-width: 30%;
  --video-slate-flag-default-opacity: 0.8;
  contain: content;

  @include h.breakpoint-s-only {
    // Constrain if used on small thumbnail:
    --video-slate-gamma-flag-height: 54px;
    --video-slate-gamma-flag-width: 95px;
  }

  @include h.breakpoint-l-only {
    --video-slate-gamma-flag-height: 85px;
    --video-slate-gamma-flag-width: 150px;
  }

  :global(body.today) & {
    --video-slate-gamma-flag-height: #{h.rem(70px)};
    --video-slate-gamma-flag-width: #{h.rem(70px)};
    --video-slate-flag-max-height: #{h.rem(125px)};
    --video-slate-flag-max-width: #{h.rem(125px)};
    --video-slate-flag-default-opacity: 1;

    @include h.breakpoint-m {
      --video-slate-flag-border-radius: 50%;
      --video-slate-gamma-flag-height: #{h.rem(125px)};
      --video-slate-gamma-flag-width: #{h.rem(125px)};
    }
  }

  picture {
    pointer-events: none;
  }

  &:hover .play {
    opacity: 1;
  }
}

:global(.jw-settings-menu) {
  z-index: 1;
}

:global(.jw-icon[aria-label='Loading']),
:global(.jw-icon[aria-label='Cargando']),
:global(.jw-icon-display[aria-label='Reproducir']),
:global(.jw-icon-display[aria-label='Play']) {
  opacity: 0;
}

:global(.jw-flag-floating .jw-icon),
:global(.started.paused .jw-icon) {
  opacity: 1;
}

.spinnerLoader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; // used to ensure no animations go out of box on mobile
  pointer-events: none;

  &.isDismissed::after {
    animation: fade 300ms linear forwards;
  }
}

.play {
  background-color: var(--video-slate-flag-default-background-color);
  height: var(--video-slate-gamma-flag-height);
  width: var(--video-slate-gamma-flag-width);
  opacity: var(--video-slate-flag-default-opacity);
  pointer-events: none;
  transition: opacity 200ms linear;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  position: relative;
  border-radius: var(--video-slate-flag-border-radius);
  border: var(--video-slate-flag-border);

  &--live {
    background-color: var(--video-slate-flag-live-background-color);
  }

  &--icon {
    display: var(--video-slate-flag-icon-display);
    fill: var(--video-slate-flag-icon-fill);
    height: var(--video-slate-flag-icon-height);
    width: var(--video-slate-flag-icon-width);
    margin-left: var(--video-slate-flag-icon-margin-left);
  }

  &::after {
    display: var(--video-slate-icon-after-display);
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    content: '';
    width: 20%;
    height: 23.2%;
    background-color: var(--video-slate-icon-after-bkg-color);
    top: 38%;
    left: 43%;
    position: absolute;
  }

  @include h.breakpoint-m {
    // Constrain if used on small thumbnail:
    max-height: var(--video-slate-flag-max-height);
    max-width: var(--video-slate-flag-max-width);
  }
}

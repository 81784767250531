@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.spinnerLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; // used to ensure no animations go out of box on mobile
  pointer-events: none;

  &::after {
    // use after since putting animations on spinnerLoader causes flashing in safari
    content: '';
    position: absolute;
    opacity: .7;
    background-color: #000;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
  }

  &.isDismissed::after {
    animation: fade 300ms linear forwards;
  }
}

.controls {
  position: absolute;
  z-index: h.get-z-index('sticky-video-player');
  bottom: 0;
  left: 0;
  right: 0;
  padding: h.rem(20px);
  color: var(--white);
  opacity: 0;
  transition: opacity 1s ease-in-out;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 2%, var(--black));
}

/* Ad Controls
   ============================= */

.adControls {
  position: absolute;
  top: 0;
  right: var(--video-player--ad-controls--right);
  z-index: h.get-z-index('sticky-video-player');
  height: var(--video-player--ad-controls--height);
  width: var(--video-player--ad-controls--width);
  background-color: var(--video-player--ad-controls--bkg);
  color: var(--video-player--ad-controls--color);
  align-items: center;
  justify-content: flex-end;
  display: flex;
  clip-path: var(--video-player--ad-controls--clip-path);

  &.bottomLeft {
    justify-content: center;
  }
}

.adTime {
  font-family: var(--video-player--ad-time--font-family);
  font-weight: var(--video-player--ad-time--font-weight);
  font-size: var(--video-player--ad-time--font-size);
  line-height: var(--video-player--ad-time--line-height);
  padding-right: h.rem(20px);
  text-transform: uppercase;
}

.adControls.bottomLeft .adTime {
  padding-right: 0;
}

.adPlay {
  pointer-events: all;
  text-align: center;
  background-color: rgba(var(--black-rgb), .3);
}

.adControls.default .adPlay {
  flex: 0 0 h.rem(44px);
  align-items: center;
  position: relative;
  height: h.rem(44px);

  @include h.breakpoint-m {
    height: h.rem(80px);
    flex: 0 0 h.rem(80px);
  }
}

.adPlay.adPlayBottomLeft {
  width: h.rem(80px);
  height: h.rem(80px);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: h.get-z-index('sticky-video-player');
  background-color: rgba(var(--black-rgb), .3);
  color: var(--white);
}

.adIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.videoPlayer {
  position: var(--video-player--position);
  height: var(--video-player--height);
  width: var(--video-player--width);

  &.adIsPlaying {
    // sets video adControls under header
    z-index: h.get-z-index('page');
  }

  & > div[id] {
    height: auto;
  }

  :global {
    .ndp-video {
      height: auto !important;
      line-height: 0;

      // Captions line-height rule causing space below player container
      .ndp-video-player {
        line-height: 0;

        > div {
          line-height: 16px;
        }
      }
    }
  }

  &.adNotPlaying {
    video {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &:global(.adHeightOverride),
  &.stuck {
    height: 100%;

    & > div[id] {
      height: 100%;
    }

    :global {
      // targets external video player to prevent video size discrepancies
      video[id^='ndp_video_player'] {
        height: 100% !important;
      }

      .ndp-video {
        height: 100% !important;

        .ndp-video-player .ndp-captions-track-region {
          display: none;

          // show caption on medium and higher
          @include h.breakpoint-m {
            display: inline-flex;
          }
        }
      }
    }
  }

  &:global(.adIsActive):not(.stuck) :global(.fw_vpaid_slot) {
    // we need to override the inline styles that are added by NDP/ad library
    z-index: h.get-z-index('sticky-video-player') !important;
  }

  &:not(.stuck) &:not(.adIsActive) {
    // height 0 and padding bottom prevents jumpiness of player controls when video initially loads
    height: 0;
    padding-bottom: 56.25%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    vertical-align: bottom;
  }

  &.showControls {
    .controls {
      opacity: 1;
    }
  }

  &.fullScreen {
    video {
      max-width: none;
      max-height: none;
    }
  }

  &.stuck {
    --main-nav-height: 60px;

    :global(body.select) &,
    :global(body.noticias) & {
      --main-nav-height: 80px;
    }
    position: fixed;
    right: 0;
    width: h.rem(174px);
    height: h.rem(81px);
    padding-right: h.rem(30px);
    background-color: var(--black);
    z-index: h.get-z-index('sticky-video-player');

    @mixin topRuleset {
      bottom: auto;
      top: calc(var(--main-nav-height) + var(--election-nav-height, 0px));
    }

    @mixin bottomRuleset {
      bottom: h.rem(36px);
      top: auto;

      // Account for potentially displayed, undetectible, 'smart app' banner on ios.
      @supports (-webkit-overflow-scrolling: touch) {
        bottom: h.rem(90px);
      }

      @include h.breakpoint-m {
        bottom: h.rem(48px);

        // Account for potentially displayed, undetectible, 'smart app' banner on ios.
        @supports (-webkit-overflow-scrolling: touch) {
          bottom: h.rem(110px);
        }
      }
    }

    @include bottomRuleset(); // Default vertical alignment

    &.alignMobileBottom {
      @include bottomRuleset();
    }

    &.alignMobileTop {
      @include topRuleset();
    }

    @include h.breakpoint-l {
      &.alignDesktopBottom {
        @include bottomRuleset();
      }

      &.alignDesktopTop {
        @include topRuleset();
      }
    }

    @include h.breakpoint-m {
      width: h.rem(270px);
      height: h.rem(135px);
    }

    @include h.breakpoint-l {
      width: h.rem(310px);
      height: h.rem(158px);
    }

    @include h.breakpoint-x {
      width: h.rem(390px);
      height: h.rem(203px);
    }

    &.stuckLB {
      width: h.rem(282px);
      height: h.rem(147px);

      @include h.breakpoint-m {
        width: h.rem(393px);
        height: h.rem(205px);
      }

      @include h.breakpoint-l {
        width: h.rem(362px);
        height: h.rem(188px);
      }

      @include h.breakpoint-x {
        width: h.rem(400px);
        height: h.rem(208px);
      }
    }

    .adControls {
      right: h.rem(30px);
      height: h.rem(40px);
      width: auto;
    }

    .adTime {
      padding-right: h.rem(10px);
      text-align: right;

      @include h.breakpoint-m {
        padding-left: h.rem(10px);
      }

      @include h.breakpoint-l {
        padding-left: h.rem(20px);
        padding-right: h.rem(20px);
      }
    }

    .adPlay {
      flex: 0 0 h.rem(40px);
      height: h.rem(40px);
      display: none;

      @include h.breakpoint-l {
        flex: 0 0 h.rem(80px);
        height: h.rem(80px);
        display: flex-item;
      }
    }

    video {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

/* Controls
   ============================= */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade {
  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.tools {
  float: right;
}

.volume {
  appearance: none;
  background: none;
  width: h.rem(100px);
  margin-left: h.rem(5px);
  outline: none;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 3px 0;

  // WEBKIT (Chrome, Safari, Opera)
  &::-webkit-slider-runnable-track {
    height: h.rem(3px);
    background: rgba(var(--white-rgb), .2);
  }

  &::-webkit-slider-thumb {
    appearance: none;
    height: h.rem(12px);
    width: h.rem(12px);
    margin-top: -4px;
    border-radius: 50%;
    background: var(--white);
    border: none;
  }

  &:focus::-webkit-slider-runnable-track {
    background: rgba(var(--white-rgb), .2);
  }

  // FIREFOX
  &::-moz-range-track {
    height: h.rem(3px);
    background: rgba(var(--white-rgb), .2);
  }

  &::-moz-range-thumb {
    height: h.rem(12px);
    width: h.rem(12px);
    border-radius: 50%;
    background: var(--white);
    border: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  // IE
  &::-ms-track {
    width: 100%;
    height: h.rem(3px);
    background: transparent;
    border-color: transparent;
    color: transparent;
    border-width: h.rem(6px 0); // leave room for thumb
  }

  &::-ms-fill-lower {
    background: rgba(var(--white-rgb), .2);
  }

  &::-ms-fill-upper {
    background: rgba(var(--white-rgb), .2);
  }

  &::-ms-thumb {
    height: h.rem(12px);
    width: h.rem(12px);
    border-radius: 50%;
    background: var(--white);
    border: none;
  }

  &::-ms-tooltip {
    display: none;
  }

  &:focus {
    outline: none;
  }
}

.time {
  font-family: var(--video-player--duration-time--font-family);
  font-weight: var(--video-player--duration-time--font-weight);
  font-size: var(--video-player--duration-time--font-size);
  line-height: var(--video-player--duration-time--line-height);
  flex-grow: 1;
}

.icon {
  color: rgba(var(--white-rgb), .6);
  flex-grow: 0;
  margin-right: h.rem(40px);
  cursor: pointer;
  transition: color 0.3s;
  display: inline-block;
  vertical-align: middle;

  &:first-child {
    margin-right: h.rem(30px);
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: rgba(var(--white-rgb), 1);
  }
}

/* Sticky player
   ============================= */

.close {
  position: absolute;
  top: h.rem(8px);
  right: h.rem(8px);
  cursor: pointer;
  z-index: 999999; // above fw vpaid ad container
}

.closeIcon {
  font-size: h.rem(13px);
  color: var(--white);
}

/* Captions tooltip
============================= */

.tooltip {
  font-family: var(--founders-mono);
  font-weight: normal;
  font-size: h.rem(11px);
  line-height: h.rem(25px);
  display: block;
  color: var(--white);
  padding: 0 h.rem(10px);
  height: h.rem(75px);
  background: rgba(var(--black-rgb), .5);
  position: absolute;
  transition: opacity .3s;
  top: h.rem(-30px);
  bottom: h.rem(-5px);
  right: 0;

  &::before {
    display: block;
    position: absolute;
    bottom: h.rem(-8px);
    border-left: 4px solid rgba(var(--black-rgb), .5);
    border-top: 4px solid rgba(var(--black-rgb), .5);
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }

  li {
    cursor: pointer;
    list-style-type: none;
  }
}

/* live video embed player
   ============================= */

.liveVideoEmbed:not(.fullScreen):not(.stuck) {
  video {
    width: 100% !important;
    height: 100% !important;
  }

  .icon {
    &:first-child {
      margin-right: h.rem(12px);

      &.volumeButton {
        margin-right: 0;
      }
    }
  }

  .controls {
    padding-left: h.rem(8px);
    padding-right: h.rem(8px);

    @include h.breakpoint-x {
      padding-left: h.rem(12px);
      padding-right: h.rem(12px);
    }

    &:not(.isLive) {
      .icon {
        margin-right: h.rem(6px);

        &.volumeButton {
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .volume {
    width: h.rem(60px);

    @include h.breakpoint-x {
      width: h.rem(130px);
    }
  }

  .adControls {
    right: 0;
    height: h.rem(40px);
    width: auto;

    @include h.breakpoint-x {
      height: h.rem(52px);
    }
  }

  .adPlay {
    height: h.rem(40px);
    max-width: h.rem(40px);

    @include h.breakpoint-x {
      height: h.rem(52px);
      max-width: h.rem(52px);
    }
  }

  .adTime {
    padding-left: 0;
    padding-right: h.rem(10px);
    text-align: right;
    width: h.rem(240px);

    @include h.breakpoint-x {
      width: h.rem(260px);
    }
  }

  &.liveVideoEmbedRail {
    .controls:not(.isLive) {
      .icon {
        margin-right: h.rem(4px);

        &.volumeButton {
          margin-right: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      @include h.breakpoint-x {
        .volume {
          width: h.rem(40px);
        }
      }
    }

    .captions {
      margin-right: h.rem(12px);
    }

    .volume {
      width: h.rem(60px);
    }

    .adControls {
      height: h.rem(40px);
    }

    .adPlay {
      height: h.rem(40px);
      max-width: h.rem(40px);
    }

    .adTime {
      width: 240px;
    }
  }
}

// ensures that click event is propagated on ios
// http://jsfiddle.net/kb3gN/1345/
.mobile {
  cursor: pointer;
}

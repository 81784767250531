@use 'assets/styles/utils/helper' as h;

.videoPlayer {
  --video-player--position: relative;
  --video-player--height: auto;
  --video-player--width: auto;
  --video-player--play-button-outer--display: block;
  --video-player--ad-time--font-family: var(--founders-mono);
  --video-player--ad-time--font-size: #{h.rem(11px)};
  --video-player--ad-time--line-height: #{h.rem(14px)};
  --video-player--ad-time--font-weight: normal;
  --video-player--duration-time--font-family: var(--founders-mono);
  --video-player--duration-time--font-weight: normal;
  --video-player--duration-time--font-size: #{h.rem(12px)};
  --video-player--duration-time--line-height: #{h.rem(11px)};
  --video-player--ad-controls--bkg: rgba(var(--black-rgb), .3);
  --video-player--ad-controls--color: var(--white);
  --video-player--ad-controls--height: #{h.rem(44px)};
  --video-player--ad-controls--width: #{h.rem(274px)};
  --video-player--ad-controls--right: 0;
  --video-player--ad-controls--clip-path: none;

  .adControls.bottomLeft {
    --video-player--ad-controls--width: #{h.rem(230px)};
  }

  @include h.breakpoint-m {
    --video-player--ad-controls--height: #{h.rem(80px)};
    --video-player--ad-controls--width: #{h.rem(310px)};
  }

  :global(body.today) & {
    --video-player--duration-time--font-family: var(--secondary-font);
    --video-player--duration-time--font-size: #{h.rem(12px)};
    --video-player--duration-time--line-height: #{h.rem(18px)};
    --video-player--ad-time--font-family: var(--secondary-font);
    --video-player--ad-time--font-weight: 700;
    --video-player--ad-time--font-size: #{h.rem(12px)};
    --video-player--ad-time--line-height: 1.5;
    --video-player--ad-controls--bkg: rgba(var(--white-rgb), .8);
    --video-player--ad-controls--color: var(--black);
    --video-player--ad-controls--height: #{h.rem(48px)};
    --video-player--ad-controls--width: #{h.rem(329px)};
    --video-player--ad-controls--right: #{h.rem(32px)};
    --video-player--ad-controls--clip-path: polygon(0 0, 100% 0, 93% 100%, 7% 100%);
    --video-player--duration-time--font-weight: 700;

    .adControls.bottomLeft {
      --video-player--ad-controls--width: #{h.rem(329px)};
    }
  }

  :global(body.today .video-package) & {
    @include h.breakpoint-s-only {
      --video-player--play-button-outer--display: none;
    }
  }
}

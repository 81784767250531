@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

:global(.jw-flag-floating) {
  :global(.jw-wrapper) {
    left: auto !important;
    right: 0 !important;
    bottom: 4em !important;
    top: auto !important;
    max-width: 282px !important;

    @include h.breakpoint-m {
      max-width: 393px !important;
      max-height: 205px !important;
    }

    @include h.breakpoint-l {
      max-width: 362px !important;
      max-height: 188px !important;
    }

    @include h.breakpoint-x {
      max-width: 400px !important;
      max-height: 208px !important;
    }
  }
}

/* stylelint-disable at-rule-no-unknown  */
/* stylelint-disable scss/at-rule-no-unknown  */
/* stylelint-disable property-no-unknown  */
.videoContainer {
  aspect-ratio: 16 / 9;

  :global(.unmuteTap) {
    pointer-events: auto;
  }
}

.infoLayer {
  container: infoLayer / size;
  isolation: isolate;
  pointer-events: none;
  overflow: hidden;
  z-index: 10;
}

:global(.jw-flag-small-player) ~ .infoLayer {
  :global(.unmuteTap) {
    margin: 0;
  }

  :global(.unmuteText) {
    display: none;
  }
}

:global(.jwplayer.jw-skin-gamma.jw-state-idle) {
  :global(.jw-controls) {
    background: none;
  }

  :global(.jw-controls-backdrop) {
    background: none;
  }
}

video::-webkit-media-text-track-display {
  text-align: center !important;
  width: 100% !important;
  max-width: 60% !important;
  min-width: unset !important;
  margin: auto !important;
  top: 90% !important;
  left: 20% !important;
  text-transform: revert !important;
  white-space: revert !important;
}

video::-webkit-media-text-track-display-backdrop {
  display: block !important;
}
